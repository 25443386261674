<script lang="ts">
	export let title: string;
	export let description: string;
	export let icon: string = 'solar:info-circle-outline';
	export let iconColor: string = 'text-surface-50';
	export let size: 'base' | 'lg' | 'sm' = 'base';
	export let classes: string = '';
</script>

<div
	class="flex flex-col items-center justify-center gap-4 {classes}"
	class:text-lg={size === 'lg'}
	class:text-sm={size === 'sm'}
>
	<iconify-icon
		{icon}
		height="2.25rem"
		class="border border-surface-50 p-2 rounded-token {iconColor}"
	></iconify-icon>

	<div class="flex max-w-xs flex-col text-center">
		<span class="font-bold text-surface-50">{title}</span>
		<!-- eslint-disable-next-line svelte/no-at-html-tags -->
		<span>{@html description}</span>
	</div>

	<slot><!-- optional fallback --></slot>
</div>
